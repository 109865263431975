import React, { FunctionComponent, useState } from 'react';
import { SimpleFormPage } from '../../../layouts/SingleBlockPage/SimplePage';
import asyncLogger from '../../../common/logger';
import { ApiClient, isOffline } from '../../../common/helpers';
import WarningIcon from '@mui/icons-material/Warning';
import { genSessionId } from '../../../layouts/SingleBlockPage';
import LoadingButton from '../../LoadingButton';
import { KnownBreakpoints } from '../../../common/constants';
import EmailField from '../../Form/EmailField';
import { HomePageLink, LoginPageLink } from '../../Form/Links';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { RoutePageProps } from '../../../common/hooks/useNamedRoute';
import { useRouter } from 'next/router';
import { RX_EMAIL } from '../../../common/helpers/validation';

const styles = {
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28px',
        marginBottom: '35px',
    }),
    continueButton: (theme: Theme) => ({
        width: '100%',
        marginBottom: '27px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            marginBottom: '30px',
        },
    }),
};

const RemindPasswordPage: FunctionComponent<RoutePageProps> = () => {
    const router = useRouter();
    const { asPath } = router;
    const parts = asPath.split('/');
    const possibleEmail = parts[parts.length - 1];

    const [sessionId, setSessionId] = useState(genSessionId());
    const [token, setToken] = useState<string>('');
    const [email, setEmail] = useState<string>(RX_EMAIL.test(
        possibleEmail,
    ) ? possibleEmail : '');
    const theme = useTheme<Theme>();
    const [validationError, setValidationError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailNotFound, setEmailNotFound] = useState(false);
    const [emailNotUnique, setEmailNotUnique] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [networkError, setNetworkError] = useState(false);
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );

    const remindHandler = async(): Promise<void> => {
        setValidationError(false);
        setEmailNotFound(false);
        setEmailNotUnique(false);
        setEmailSent(false);

        if (validationError) {
            return ;
        }

        setLoading(true);

        try {
            const { data } = await ApiClient.request<{
                data: { customerResetPassword: boolean };
            }>('/sendReset', {
                body: { email, token },
                withAuth: false,
            });

            if (data?.customerResetPassword) {
                if (data?.customerResetPassword) {
                    setEmailSent(true);
                    setLoading(false);

                    return;
                }

                setEmailNotFound(true);
                setLoading(false);
                setSessionId(genSessionId());

                return;
            } else {
                setEmailNotFound(true);
                setLoading(false);
                setSessionId(genSessionId());

                return;
            }
        } catch (err) {
            asyncLogger.error(err);

            setNetworkError(isOffline(err));
            setEmailNotFound(true);
            setLoading(false);
            setSessionId(genSessionId());

            return;
        }
    };

    const handleChange = (prop: string) => (
        event: React.ChangeEvent<HTMLElement & { value: any }>,
    ) => {
        if (prop === 'email') {
            setValidationError(false);
            setEmailNotFound(false);
            setEmailNotUnique(false);
            setEmailSent(false);
        }

        setEmail(event.target.value);
    };

    const handleCaptchaVerify = (token: string) => {
        setToken(token);
        setLoading(false);
    };

    return <SimpleFormPage
        networkError={ networkError }
        setNetworkError={ setNetworkError }
        title="Reset Password"
        withActions={ true }
        withReCaptcha={ true }
        sessionId={ sessionId }
        onReCaptchaVerify={ handleCaptchaVerify }
    >
        { !isMobile &&
            <Typography sx={ styles.title }>Remind Password</Typography>
        }
        { (validationError || emailNotUnique || emailNotFound) &&
            <Alert
                severity={ 'error' }
                iconMapping={{ error: <WarningIcon/> }}
            >{ validationError
                ? 'Provided Email is not a valid e-mail address'
                : emailNotUnique
                    ? 'The Email address is not unique!'
                    : 'Email not found!'
            }</Alert>
        }
        { emailSent &&
            <Alert severity="success">
                Email with further instructions has been sent!
            </Alert>
        }
        <EmailField
            value={ email }
            onChange={ handleChange('email') }
            onError={ () => setValidationError(true) }
            onSuccess={ () => setValidationError(false) }
        />
        <LoadingButton
            loading={ loading }
            disabled={ loading || validationError || !email }
            variant="contained"
            color="secondary"
            aria-label="remind password"
            onClick={ remindHandler }
            sx={ styles.continueButton }
            data-testid="btn-remind-password"
        >
            Remind Password
        </LoadingButton>
        <Box marginBottom="10px"><LoginPageLink /></Box>
        <Box><HomePageLink /></Box>
    </SimpleFormPage>;
};

export default RemindPasswordPage;
