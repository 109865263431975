import { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@mui/material';

const LoadingButton: FunctionComponent<ButtonProps & {
    loading: boolean,
}> = props => {
    const {
        loading,
        children,
        ...buttonProps
    } = props;

    return <Button
        data-testid="btn-loading"
        { ...buttonProps }
    >
        { loading ? 'Loading...' : children }
    </Button>;
};

export default LoadingButton;
