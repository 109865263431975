import React, { FunctionComponent } from 'react';
import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { isEmail } from '../../common/helpers/validation';

const styles = {
    emailInput: {
        width: '100%',
        marginBottom: '20px',
    },
};

export type EmailFieldProps = TextFieldProps & {
    email?: string;
    onError?: () => void;
    onSuccess?: () => void;
    extraValidationRule?: RegExp;
};

const EmailField: FunctionComponent<EmailFieldProps> = props => {
    const {
        email,
        onError,
        onChange,
        onSuccess,
        extraValidationRule,
        ...textFieldProps
     } = props;

    const validate = (email: string) => {
        return (extraValidationRule && extraValidationRule.test(email)) ||
            isEmail(email);
    };

    return <TextField
        value={ email }
        label="Email"
        id="email"
        arial-label="email"
        sx={ styles.emailInput }
        { ...textFieldProps }
        onChange={ event => {
            if (validate(event.target.value)) {
                onSuccess && onSuccess();
            }

            onChange && onChange(event);
        }}
        onBlur={ event => {
            if (!validate(event.target.value)) {
                onError && onError();
            }
        }}
    />;
};

export default EmailField;
